<template>
  <v-row class="ma-0 pa-0 dark-blue">
    <v-col class="ma-0 pa-0 text-center d-flex justify-center align-center">
      <div v-if="!file">
        <div class="uploadFile">
          <v-icon size="5vw" class="">mdi-cloud-upload</v-icon>
          <input
            name="flat"
            type="file"
            @change="inputFile"
            id="file"
            ref="file"
          />
        </div>
      </div>
      <div v-else class="d-flex flex-column">
        <v-icon size="5vw">mdi-file-cloud</v-icon>
        <v-btn
          type="button"
          class="btn btn-primary removeFile"
          color="error"
          @click="removeFile"
          small
          >Remove File</v-btn
        >
      </div>
    </v-col>
    <v-col class="ma-0 pa-0" cols="8">
      <v-row class="ma-1">
        <v-col cols="12" class="ma-0 pa-4">
          <v-text-field
            label="File Name"
            type="text"
            v-model="name"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="ma-0 pa-4">
          <v-text-field
            label="Created by"
            type="text"
            v-model="user.name"
            outlined
            dense
            disabled
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ma-1"> </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { parseFromIsoToFormat } from "@/helpers/mocks/dates";

import { mapState } from "vuex";
export default {
  data() {
    return {
      file: "",
      name: "",
      date: "",
    };
  },
  methods: {
    removeFile() {
      this.file = "";
      0;
      this.name = "";
      this.date = "";
    },
    inputFile(e) {
      var files = e.target.files || e.dataTransfer.files;
      let file = e.target.files[0];

      if (!files.length) {
        return;
      }

      this.file = file;
      this.name = file.name;
      this.date = parseFromIsoToFormat(new Date().toISOString(), "LL/dd/yyyy");

      if (
        this.extension === "php" ||
        this.extension === "exe" ||
        this.extension === "bat" ||
        this.extension === "jpg" ||
        this.extension === "jpeg"
      ) {
        alert("invalid format");
      }

      this.$emit("fileData", {
        fileData: { file: this.file, name: this.name, date: this.date },
      });
    },
  },
  computed: {
    ...mapState(["user"]),
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },
  },
};
</script>

<style scoped>
.uploadFile {
  height: 30%;
  position: relative;
}

.uploadFile:hover {
  border: 1px solid #2e94c4;
}

.uploadFile input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
@media only screen and (min-width: 1264px) {
  .upload-top {
    max-width: 80%;
  }
}

@media only screen and (min-width: 1500px) {
  .upload-top {
    max-width: 60%;
  }
}
</style>
