<template>
  <v-row>
    <v-row>
      <NewOrderAction
        :step="step"
        :customerData="customerData"
        :ordersData="ordersData"
        :orderDataContainers="orderDataContainers"
        :creditsSelected="creditsSelected"
        :inventoryData="inventoryData"
        @loadDraft="loadDraft"
      />
    </v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-stepper
            v-model="step"
            class="pa-0 ma-auto overflow-y-auto"
            :style="{ maxHeight: '80vh' }"
          >
            <v-stepper-header class="pa-5">
              <v-stepper-step
                :complete="step > index + 1"
                :step="index + 1"
                class="pa-0 text-capitalize font-weight-bold"
                v-for="({ title }, index) in stepperData"
                :key="title"
              >
                {{ title }}
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <!-- <div
                v-for="(
                  { props, on, component, stepComponent, width }, index
                ) in stepperData"
                :key="index"
              >
                {{ index }} -->
              <!-- <v-stepper-content :step="index + 1" class="ma-0 pa-3"> -->
              <ValidationObserver v-slot="{ invalid, errors, dirty }">
                <v-row
                  class="flex-column ma-auto elevation-5 dark_blue align-center py-5"
                  :style="{
                    width: stepperData[step - 1].width,
                  }"
                >
                  <component
                    v-bind="stepperData[step - 1].props"
                    v-on="stepperData[step - 1].on"
                    :is="stepperData[step - 1].component"
                    :step="step"
                    :errors="errors"
                    :ObserverDirty="dirty"
                    :ObserverInvalid="invalid"
                  />

                  <v-col cols="9" class="pt-5 d-flex justify-space-between">
                    <v-btn
                      @click="backStep(stepperData[step - 1].stepComponent)"
                      v-if="step != 1"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      color="primary"
                      v-if="stepperData[step - 1].stepComponent === 3"
                      @click="saveInventory"
                    >
                      Add inventory
                    </v-btn>
                    <!-- <v-col
                v-if="flagIsGateBuySoft && !allContainers.includes(item.ContainerNo)"
                cols="auto"
                class="d-flex"
              >
                <v-tooltip bottom color="warning">
                  <template v-slot:activator="{ on }">
                    <v-btn color="#20212E" v-on="on" class="lighten-2 rounded-0" block>
                      <v-icon small dark color="red" class="ma-auto">mdi-alert</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ `Container ${item.ContainerNo}  is not available` }}</span>
                </v-tooltip>
              </v-col> -->
                    <v-tooltip
                      bottom
                      :disabled="
                        errorBalance(stepperData[step - 1].stepComponent) &&
                        checkError(errors) === null
                      "
                      v-if="stepperData[step - 1].stepComponent !== 1"
                      color="warning"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            :disabled="
                              !errorBalance(stepperData[step - 1].stepComponent) ||
                              checkError(errors) !== null
                            "
                            class="button button--outlined"
                            @click="updateStep(stepperData[step - 1].stepComponent)"
                          >
                            {{
                              stepperData[step - 1].stepComponent === 6
                                ? "create"
                                : "Continue"
                            }}
                          </v-btn>
                        </div>
                      </template>
                      <span class="font-weight-bold dark_blue--text">
                        {{
                          !errorBalance(stepperData[step - 1].stepComponent)
                            ? "Credit amount cannot be greater than the total amount of the order"
                            : checkError(errors, stepperData[step - 1].stepComponent)
                        }}</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </ValidationObserver>
              <!-- </v-stepper-content> -->
              <!-- </div> -->
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FormOrders from "@/components/OM/AllOrders/FormOrders.vue";
import CustomerTable from "@/components/OM/AllOrders/CustomerTable.vue";
import NewOrdersCredit from "@/components/OM/AllOrders/NewOrdersCredit.vue";
import OrderPreview from "@/components/OM/AllOrders/OrderPreview.vue";
import FormCreateInventory from "@/components/OM/AllOrders/FormCreateInventory.vue";
import FormOrdersTable from "@/components/OM/AllOrders/FormOrdersTable.vue";
import { ValidationObserver } from "vee-validate";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import NewOrderAction from "@/components/OM/NewOrder/NewOrderAction.vue";
import { gateType } from "@/helpers/gate_buy.js";

export default {
  components: {
    FormOrders,
    CustomerTable,
    OrderPreview,
    FormOrdersTable,
    ValidationObserver,
    NewOrderAction,
    NewOrdersCredit,
    FormCreateInventory,
  },
  async created() {
    await this.getDraft();
  },
  mounted() {
    this.ordersData = this.ordersDataStore.map(
      ({ Size, Type, Cndtn, CountryAlhpa2, StateAlpha2, CityName, Depot, DepotId }) => {
        return {
          equipmentSize: Size,
          equipmentType: Type,
          equipmentCondition: Cndtn,
          depot: Depot,
          country: CountryAlhpa2,
          state: StateAlpha2,
          city: CityName,
          depotId: DepotId,
          qty: "",
          buyerPrice: "",
          temType: "Container",
        };
      }
    );
  },
  computed: {
    ...mapState({
      ordersTerm: (state) => state.moduleOrders.ordersTerm,
      ordersType: (state) => state.moduleOrders.ordersType,
      allDepots: (state) => state.moduleOrders.allDepots,
      inventoryQtySumms: (state) => state.moduleQtySumm.inventoryQtySumms,
      credits: (state) => state.moduleOrders.credits,
      draft: (state) => state.moduleOrders.draft,
    }),
    ...mapGetters({
      ordersDataStore: "moduleOrders/ordersData",
      getUserType: "getUserType",
    }),
    creditsSelectedBalance() {
      let total = 0;
      this.creditsSelected.map(({ balance }) => {
        total += Number(balance);
      });

      return total;
    },
    orderTypeData() {
      const res = this.ordersType.filter((x) => x.id === this.customerData.orderTypeId);
      return res;
    },
    totalAmount() {
      let total = 0;
      this.ordersData.map(({ qty, buyerPrice, addInventory }) => {
        if (addInventory) {
          total += Number(qty) * Number(buyerPrice);
        } else if (addInventory === undefined) total += Number(qty) * Number(buyerPrice);
      });

      return total;
    },
    isGateBuyFlagSoft() {
      const isGateBuy = gateType.order_type_desc_soft === this.orderTypeData[0]?.typeDesc;
      return isGateBuy;
    },
    isGateBuyFlag() {
      const isGateBuy = gateType.order_type_desc === this.orderTypeData[0]?.code;
      return isGateBuy;
    },
    stepperData() {
      let arr = [
        {
          title: "Select Customer",
          stepComponent: 1,
          width: "70%",
          component: CustomerTable,
          on: {
            setCustomerData: this.setCustomerData,
            nextStep: this.nextStep,
          },
        },
        {
          title: "customer data",
          stepComponent: 2,
          props: {
            customerData: this.customerData,
          },
          on: {
            setCustomerData: this.setCustomerData,
          },
          component: FormOrders,
          width: "70%",
        },
        {
          title: "add inventory",
          stepComponent: 3,
          component: FormCreateInventory,
          props: {
            data: this.inventoryData,
          },
          width: "100%",
        },

        {
          title: "order data",
          stepComponent: 4,
          component: FormOrdersTable,
          props: {
            ordersData: this.ordersData,
            customerData: this.customerData,
            orderDataContainers: this.orderDataContainers,
            orderTypeId: this.customerData.orderTypeId,
          },
          on: {
            setOrdersTableData: this.setOrdersTableData,
            setOrdersTableDataContainer: this.setOrdersTableDataContainer,
          },
          width: "100%",
        },
        {
          stepComponent: 5,
          title: !this.credits.length ? "Not Credits Available" : "Credits",
          component: NewOrdersCredit,
          props: {
            custData: this.customerData,
            append: {
              totalAmount: false,
              balance: true,
            },
            creditsSelected: this.creditsSelected,
          },
          on: {
            nextStep: this.nextStep,
            setCreditsSelected: this.setCreditsSelected,
          },
          width: "70%",
        },
        {
          stepComponent: 6,
          title: "Preview",
          component: OrderPreview,
          props: {
            customerData: this.customerData,
            ordersData: this.ordersData,
            orderDataContainers: this.orderDataContainers,
            creditsSelected: this.creditsSelected,
          },
          on: {
            setCustomerData: this.setCustomerData,
            setOrdersTableData: this.setOrdersTableData,
            setOrdersTableDataContainer: this.setOrdersTableDataContainer,
          },
          width: "100%",
        },
      ];

      if (this.getUserType !== "VB") {
        arr = arr.filter((x) => x.stepComponent !== 3);
      }

      if (this.isGateBuyFlag) {
        arr = arr.filter((x) => x.stepComponent !== 5);
      }

      return arr;
    },
  },
  data() {
    return {
      step: 1,
      customerData: {},
      ordersData: [],
      orderDataContainers: {},
      creditsSelected: [],

      loadingFormOrdersTable: false,
      inventoryData: {},
    };
  },
  watch: {
    stepperData: {
      handler(newValue, oldValue) {
        if (this.step === oldValue.length) {
          this.step = newValue.length;
          console.log(this.step);
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      setSpinnerShow: "setSpinnerShow",
      setInventoryQtySumms: "moduleQtySumm/setInventoryQtySumms",
      setCredits: "moduleOrders/setCredits",
    }),
    ...mapActions({
      // createNewOrder: "moduleOrders/createNewOrder",
      createOrder: "moduleOrders/createOrder",
      getDepotsAll: "moduleOrders/getDepotsAll",
      saveCustomer: "moduleOrders/saveCustomer",
      updateCustomFieldValue: "moduleOrders/updateCustomFieldValue",
      createCustomFieldByOrder: "moduleOrders/createCustomFieldByOrder",
      applyCredit: "moduleOrders/applyCredit",
      getDraft: "moduleOrders/getDraft",

      savedepotManage: "savedepotManage",
      saveTBDContainersStore: "moduleQtySumm/saveTBDContainers",
      isGateBuy: "moduleOrders/isGateBuy",
    }),
    loadDraft() {
      this.step = this.draft.step;
      this.customerData = this.draft.customerData;
      this.ordersData = this.draft.ordersData;
      this.orderDataContainers = this.draft.orderDataContainers;
      this.creditsSelected = this.draft.creditsSelected;
      this.inventoryData = this.draft.inventoryData;
    },
    errorBalance(stepComponent) {
      if (stepComponent === 5 || stepComponent === 6) {
        // 5 is credits
        return this.creditsSelectedBalance <= this.totalAmount;
      }

      return true;
    },
    checkError(errors) {
      let error = null;

      for (let value in errors) {
        if (errors[value].length > 0) {
          error = errors[value][0];

          break;
        }
      }
      if (error !== null)
        this.ordersData.map((x, i) => (error = error.replace(`_${i}`, "")));

      return error;
    },

    backStep(stepComponent) {
      this.step = this.step - 1;
      if (stepComponent === 6 && (!this.credits.length || this.isGateBuyFlag))
        this.step -= 1;
    },
    updateStep(stepComponent) {
      if (stepComponent === 6)
        // preview component
        this.sendData();
      else this.step += 1;
    },

    async sendData() {
      let docNo;
      let orderFiltered = this.ordersData.filter((x) =>
        x.added ? (x.addInventory ? x : null) : x
      );

      let data = {
        ...this.customerData,
        lineItems: orderFiltered,
        transStepId: 2,
      };

      if (!this.customerData.customerEntityId) {
        docNo = await this.createOrder({
          order: data,
          customer: {
            organization_descr: this.customerData.customerName,
            organization_type: "C",
            primary_email: this.customerData.primary_email,
            externalCustomerNumber: this.customerData.externalCustomerNumber,
          },

          isGateBuy: this.isGateBuyFlag,
        });
      } else {
        docNo = await this.createOrder({
          order: data,
          customFields: this.customerData.customFields,
          isGateBuy: this.isGateBuyFlag,
        });
      }
      if (!this.isGateBuyFlag) {
        if (this.creditsSelected.length) {
          // apply credits
          let creditsSelected = this.creditsSelected.map((x) => {
            return {
              invoice_no: x.invoice_no,
              amount: x.balance,
            };
          });

          let dataCredits = {
            order_from: creditsSelected,
            order_to: docNo,
          };

          this.applyCredit({ data: dataCredits });
        }
      }
      this.setSpinnerShow(true);

      setTimeout(() => {
        // if (this.$route.fullPath === "/orders/" + docNo) {
        //   // this.setNewOrderFlag(false);
        //   this.setOrdersData([]);
        // } else {
        this.$router.push("/orders/" + docNo);
        // }
        this.setSpinnerShow(false);
      }, 2000);
    },

    async saveInventory() {
      const depot = {
        code: this.inventoryData.code,
        name: this.inventoryData.name,
        email: this.inventoryData.email,
        address: this.inventoryData.address,
      };
      let depotId;
      if (this.inventoryData.Depot === "Add New Depot") {
        const data = await this.savedepotManage({
          flagNew: true,
          data: depot,
          statusData: {
            EffectiveFrom: this.inventoryData.EffectiveFrom,
          },
        });

        if (!data) return;

        depotId = data.data.insertId;
      }

      let { Size, Cndtn, Type, Depot, Country, State, City, qtyTBD } = this.inventoryData;
      const depotValue =
        this.inventoryData.Depot === "Add New Depot" ? this.inventoryData.code : Depot;
      let data = {
        numContainers: qtyTBD,
        Size,
        Type,
        Depot: depotValue,
        Cndtn,
        Country: Country.iso2,
        State: State.name,
        City: City.name,
        Status: "IN-CY",
        added: true,
      };

      try {
        await this.saveTBDContainersStore({ data });
        this.ordersData.push({
          equipmentSize: Size,
          equipmentType: Type,
          equipmentCondition: Cndtn,
          depot: depotValue,
          country: Country.iso2,
          state: State.name,
          city: City.name,
          depotId,
          qty: qtyTBD,
          buyerPrice: "",
          temType: "Container",
          newRow: false,
          ContainerNo: "",
          searchContainerNo: null,
          total: 0,
          added: true,
          addInventory: true,
          maxQty: qtyTBD,
        });
      } catch (error) {}
    },

    ////////

    setCustomerData(data) {
      this.customerData = { ...data };
    },
    setOrdersTableData(data) {
      this.ordersData = JSON.parse(JSON.stringify(data));
    },
    setOrdersTableDataContainer(containers) {
      this.orderDataContainers = JSON.parse(JSON.stringify(containers));
    },
    setCreditsSelected(creditInfo) {
      this.creditsSelected = JSON.parse(JSON.stringify(creditInfo));
    },
    nextStep() {
      this.step += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.create-order-table {
  position: absolute;
  bottom: 0;
}
</style>
