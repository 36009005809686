<template>
  <v-row :style="{ gap: '0px 5px' }" class="dark-blue pa-2 items-center">
    <v-col cols="auto" class="pa-3 px-5 text-center d-flex align-center">
      <v-btn class="button elevation-5" @click="returnAllOrders">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-col>

    <v-col class="pa-3 px-5 text-center d-flex justify-end">
      <v-btn class="button elevation-5 ma-2" color="primary" @click="saveAsDraft">
        save as draft
      </v-btn>
      <template v-if="draft !== null">
        <v-btn
          class="button elevation-5 ma-2"
          color="primary"
          @click="$emit('loadDraft')"
        >
          load draft
        </v-btn>
        <v-btn class="button elevation-5 ma-2" color="error" @click="deleteDraft">
          delete draft
        </v-btn>
      </template>
    </v-col>
  </v-row>
  <!-- </v-row> -->
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: [
    "step",
    "customerData",
    "ordersData",
    "orderDataContainers",
    "creditsSelected",
    "inventoryData",
  ],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      draft: (state) => state.moduleOrders.draft,
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      createDraftOrder: "moduleOrders/createDraftOrder",
      deleteDraftOrder: "moduleOrders/deleteDraftOrder",
    }),

    returnAllOrders() {
      this.$router.push("/orders");
    },
    saveAsDraft() {
      let data = {
        step: this.step,
        customerData: this.customerData,
        ordersData: this.ordersData,
        orderDataContainers: this.orderDataContainers,
        creditsSelected: this.creditsSelected,
        inventoryData: this.inventoryData,
      };

      this.createDraftOrder({
        data,
      });
    },

    deleteDraft() {
      this.deleteDraftOrder();
    },
  },
};
</script>

<style lang="scss" scoped></style>
