<template>
  <modal width="50%" :show="show" :title="title">
    <template v-slot>
      <v-stepper v-model="step" class="pa-0 ma-auto">
        <v-stepper-header class="pa-5" :style="{ height: 'auto' }">
          <v-stepper-step
            :complete="step > index + 1"
            :step="index + 1"
            class="pa-0 text-capitalize font-weight-bold"
            v-for="({ title }, index) in stepperData"
            :key="title"
          >
            {{ title }}
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content
            :step="index + 1"
            class="ma-0 pa-3"
            v-for="({ props, component, stepComponent }, index) in stepperData"
            :key="index"
          >
            <ValidationObserver v-slot="{ invalid, errors, dirty }">
              <v-row
                class="flex-column ma-auto elevation-5 dark_blue align-center py-5"
                :style="{
                  width: step === 3 ? '100%' : step === 4 ? '100%' : '70%',
                }"
              >
                <component
                  v-if="step === stepComponent"
                  :is="component"
                  v-bind="props"
                  :errors="errors"
                  :ObserverDirty="dirty"
                  :ObserverInvalid="invalid"
                />

                <v-col
                  cols="9"
                  class="pt-5 d-flex"
                  :class="[
                    step !== 1 ? 'justify-space-between' : 'justify-center',
                  ]"
                >
                  <v-btn @click="step = step - 1" v-if="step != 1">
                    Back
                  </v-btn>
                  <v-btn
                    class="button button--outlined"
                    @click="step += 1"
                    v-if="step !== 2"
                  >
                    Continue
                  </v-btn>

                  <v-btn
                    color="primary"
                    v-if="step === 2"
                    @click="sendData"
                    :disabled="step === 2 && invalid"
                  >
                    Create
                  </v-btn>
                </v-col>
              </v-row>
            </ValidationObserver>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-row class="ma-0 pa-0" :style="{ width: '100%' }">
        <v-btn
          class="elevation-5"
          color="primary"
          text
          @click="$emit('showCustomerPayment', false)"
          block
        >
          Close
        </v-btn>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import SimpleForm from "@/components/General/SimpleForm.vue";
import { ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex";
import {
  parseDateFromFormat,
  parseDate,
  parseFromIsoToFormat,
} from "@/helpers/mocks/dates";

export default {
  props: ["show", "title", "docNo"],
  created() {
    this.data.invoice_no = this.tabDetails.docNo;
    this.data.amount_paid = this.tabDetails.vit_vendorTotal;
    this.data.payment_date = parseFromIsoToFormat(
      new Date().toISOString(),
      "yyyy-MM-dd",
    );
  },
  components: {
    Modal,
    SimpleForm,
    ValidationObserver,
  },
  computed: {
    ...mapState({
      tabSelected: (state) => state.moduleOrders.tabSelected,
    }),
    tabDetails() {
      let details = {
        nereus_no: this.tabSelected.details.nereus_no,
        docNo: this.tabSelected.details.docNo,
        vit_vendorTotal: this.tabSelected.details.vit_vendorTotal,
        docDate: parseDate(this.tabSelected.details.docDate),
        coName: this.tabSelected.details.coName,
        amount_paid: this.tabSelected.details.amount_paid,
        amount_owed: this.tabSelected.details.amount_owed,
      };
      return details;
    },
    stepperData() {
      return [
        {
          title: "Payment Info",
          stepComponent: 1,
          props: {
            cols: "8",
            fields: [
              {
                text: "Nereus Order #",
                value: "nereus_no",
                rules: "required",
                disabled: true,
              },
              {
                text: "Doc #",
                value: "docNo",
                rules: "required",
                disabled: true,
              },
              {
                text: "Total Amount",
                value: "vit_vendorTotal",
                rules: "required",
                disabled: true,
              },
              {
                text: "Invoice Date",
                value: "docDate",
                rules: "required",
                disabled: true,
              },
              {
                text: "Amount Paid",
                value: "amount_paid",
                rules: "required",
                disabled: true,
              },
              {
                text: "Buyer (Customer)",
                value: "coName",
                rules: "required",
                disabled: true,
              },
              // {
              //   text: "Total Owed",
              //   value: "amount_owed",
              //   rules: "required",
              //   disabled: true,
              // },
            ],
            data: this.tabDetails,
          },
          component: SimpleForm,
        },
        {
          title: "Payment Data",
          stepComponent: 2,
          props: {
            cols: "8",
            fields: [
              {
                text: "Doc #",
                value: "invoice_no",
                rules: "required",
                readonly: true,
              },
              {
                text: "Date",
                value: "payment_date",
                rules: "required",
                readonly: false,
                type: "DatePicker",
              },
              {
                text: "Amount Paid",
                value: "amount_paid",
                rules: "required",
                readonly: false,
              },
              {
                text: "Payment No.",
                value: "payment_no",
                rules: "required",
                readonly: false,
              },
              {
                text: "Currency",
                value: "currency",
                rules: "required|max:3",
                readonly: false,
              },
              {
                text: "Optional",
                value: "optional_label",
                type: "label",

                readonly: true,
              },

              // {
              //   text: "External Invoice No.",
              //   value: "ExternalInvoiceNo",

              //   readonly: false,
              // },

              {
                text: "Remarks",
                value: "remarks",
                readonly: false,
              },
            ],
            data: this.data,
          },
          component: SimpleForm,
        },
      ];
    },
  },
  data() {
    return {
      step: 1,
      data: {
        invoice_no: "",
        payment_no: "",
        payment_date: "",
        amount_paid: "",
        currency: "USD",
        remarks: "",
      },
    };
  },
  methods: {
    ...mapActions({
      createPayment: "moduleOrders/createPayment",
      getOrderByDocNo: "moduleOrders/moduleDetails/getOrderByDocNo",
    }),
    sendData() {
      console.log("data", data);
      let data = {
        ...this.data,
        payment_date: this.data.serverDate_payment_date
          ? parseDateFromFormat(
              this.data.serverDate_payment_date,
              "yyyy-LL-dd HH-mm-ss",
              "yyyy-LL-dd",
            )
          : this.data.payment_date,
      };

      this.createPayment({ data });
      this.getOrderByDocNo(this.docNo);
      this.$emit("showCustomerPayment", false);
    },
  },
};
</script>

<style></style>
