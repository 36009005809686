<template>
  <!-- <h1 class="text-center">{{ title }}</h1> -->
  <h1 class="text-center"></h1>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style></style>
