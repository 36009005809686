<template>
  <modal width="80%" :show="show" :title="title" background="white">
    <template v-slot>
      <v-card
        class="flex-column d-flex ma-1 pa-2"
        style="height: 100%; border-radius: 0"
        light
      >
        <v-row class="fill-height">
          <v-col cols="12" class="fill-height" :style="{ maxHeight: '400px' }">
            <v-textarea outlined v-model="data" />
          </v-col>
        </v-row>
      </v-card>
    </template>

    <template v-slot:actions>
      <v-row class="ma-0 pa-0" style="background-color: white">
        <v-col class="pa-2" cols="6">
          <v-btn class="elevation-5" color="primary" block @click="loadContainers">
            save
          </v-btn>
        </v-col>
        <v-col class="pa-2" cols="6">
          <v-btn
            class="elevation-5 primary--text"
            color="transparent"
            block
            @click="$emit('closeModalPasteContainers')"
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import { getContainerRates } from "@/helpers/api/Organizations/index.js";
export default {
  components: {
    Modal,
  },
  props: ["show", "title"],

  data() {
    return {
      step: 0,
      data: null,
    };
  },
  methods: {
    async loadContainers() {
      let data = this.data.replaceAll(/[\s"']/g, ",");

      data = data.split(",");

      const dataContainers = await getContainerRates(data);
      this.$emit("loadOrdersData", dataContainers.data);
    },
  },
};
</script>

<style></style>
