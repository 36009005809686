<template>
  <modal width="50%" :show="show" :title="title">
    <template v-slot>
      <v-card
        class="mx-auto my-5 pa-5 rounded-0 elevation-5"
        outlined
        color="#20212E"
      >
        <ValidationObserver v-slot="{ invalid, errors, dirty }">
          <SimpleForm
            v-bind="simpleFormData.props"
            :errors="errors"
            :ObserverDirty="dirty"
            :ObserverInvalid="invalid"
          />
        </ValidationObserver>

        <label>
          {{ depot }}
        </label>

        <v-tabs
          v-model="tab"
          class="pa-0 ma-0 mt-3"
          background-color="dark_blue"
          left
          leave-absolute
        >
          <v-tab v-for="tabData in tabsData" :key="tabData.title" class="tab">
            {{ tabData.title }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            :transition="false"
            v-for="tabData in tabsData"
            :key="tabData.title"
          >
            <v-row>
              <v-container fluid class="pa-0 ma-1">
                <component
                  :is="tabData.component"
                  v-bind="tabData"
                  v-on="tabData.on"
                />
              </v-container>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-row
        class="ma-0 pa-0"
        :style="{ width: '100%', justifyContent: 'space-evenly' }"
      >
        <!-- <v-col cols="">
          <v-btn
            class="elevation-5"
            color="primary"
            text
            @click="generateInvContainerItems"
            block
          >
            Generate Container Item Rows (Dev)
          </v-btn>
        </v-col> -->
        <v-col cols="">
          <v-btn
            class="elevation-5"
            color="primary"
            text
            @click="generateInvContainerItems"
            block
          >
            Save
          </v-btn>
        </v-col>
        <v-col cols="">
          <v-btn
            class="elevation-5"
            color="primary"
            text
            @click="$emit('showIssueRelease', false)"
            block
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </modal>
</template>

<script>
import Modal from "@/layout/Modal.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import SimpleForm from "@/components/General/SimpleForm.vue";
import { ValidationObserver } from "vee-validate";

import { parseFromIsoToFormat } from "@/helpers/mocks/dates";
import UploadRelease from "@/components/OM/ModalIssueRelease/UploadRelease";
import PreviewRelease from "@/components/OM/ModalIssueRelease/PreviewRelease";
import { update_equipment_on_order } from "@/helpers/api/Orders";
import { remote_upload_pdf_to_order } from "@/helpers/api/Orders/index.js";
import { trans_log_order } from "@/helpers/api/Orders/index.js";

import { DateTime } from "luxon";

import {
  get_single_order_details_by_docNo,
  get_single_order_headers_by_docNo,
  get_single_order_details_by_docNo_docType_inv,
  get_single_order_headers_by_docNo_docType_inv,
} from "@/helpers/api/indexv2";

import axios from "axios";

export default {
  props: ["show", "title", "docNo"],

  components: {
    Modal,
    SimpleForm,
    ValidationObserver,
    UploadRelease,
    PreviewRelease,
  },
  async created() {
    this.tab = this.tabsData[0];

    console.log("this.tabsData[0];", this.tabsData[0]);

    console.log("tabDetails", this.tabDetails);

    const token = localStorage.getItem("access_token");

    const details = await get_single_order_details_by_docNo_docType_inv(
      this.docNo,
    );
    const headers = await get_single_order_headers_by_docNo_docType_inv(
      this.docNo,
    );

    console.log("created details", details);
    console.log("created headers", headers);

    this.lineItems = details;
    this.depot = details.Location;
    this.docId = headers[0].id;

    console.log("this.lineItems ", this.lineItems);
  },
  computed: {
    ...mapGetters({
      tabDetails: "moduleOrders/tabDetails",
    }),
    simpleFormData() {
      return {
        props: {
          cols: "8",
          fields: [
            {
              text: "Release Ref #",
              value: "release_ref",
            },
            {
              text: "Date",
              type: "DatePicker",
              value: "release_date",
            },
          ],
          data: this.data,
        },
      };
    },
    tabsData() {
      return [
        {
          title: "Attachments",
          component: UploadRelease,
          on: { fileData: this.fileData },
        },
        {
          title: "Preview",
          component: PreviewRelease,
        },
        {
          title: "Release Comments",
          component: PreviewRelease,
        },
      ];
    },
  },
  data() {
    return {
      tab: null,
      lineItems: [],
      data: {
        release_ref: "",
        release_date: parseFromIsoToFormat(
          new Date().toISOString(),
          "yyyy-MM-dd HH:mm:ss",
        ),
        depot: null,
        release_qty: "",
        file: null,
      },
      depot: null,
    };
  },
  methods: {
    ...mapActions({
      getOrderByDocNo: "moduleOrders/moduleDetails/getOrderByDocNo",
    }),
    sendData() {
      console.log("send data");
      // console.log(this.data);
    },
    fileData({ fileData }) {
      this.data.file = fileData;
    },
    async generateInvContainerItems() {
      console.log("send data");
      console.log(this.data);

      const docNo = this.docNo;

      let outLineItems = this.lineItems.map((x) => {
        x.oldQty = x.qty;
        return x;
      });

      const data = await update_equipment_on_order({
        docNo,
        release_date: this.data.release_date,
        release_ref: this.data.release_ref,
        lineItems: outLineItems,
      });

      this.$emit("showIssueRelease", false);

      const token = localStorage.getItem("access_token");

      const url = process.env.VUE_APP_SCRIPTCASE_API_PATH;
      window.open(
        `${url}/rptVendToCustRelNote/?v_invoiceID=${this.docId}&v_invoiceNO=${this.docNo}&token=${token}`,
        "_blank",
      );
      this.getOrderByDocNo(this.docNo);

      try {
        trans_log_order({
          order_id: id,
          doc_table: "release_note",
          trans_desc: "Release print/view opened",
          application: "rptVendToCustQuote",
          trans_action: "Print",
          old_value: "",
          new_value: "",
        });
      } catch (error) {
        console.log(error);
      }

      await remote_upload_pdf_to_order({
        url: `https://pdf-gen-service-vjuqlq6gsq-uw.a.run.app/?url=${url}/rptVendToCustRelNote/?v_invoiceID=${this.docId}&v_invoiceNO=${this.docNo}&token=${token}`,
        docType: "SALEINVOICE",
        category: "REL",
        sourceType: "FILEATTACH",
        sourceid: docNo,
        permissions: "write,read",
        organization_id: null,
      });

      // setTimeout(function () {
      //   window.location.reload(1);
      // }, 3000);
      //
    },
  },
};
</script>

<style></style>
