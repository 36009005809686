<template>
  <v-row dense class="top-panel transparent">
    <Tabs />
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Tabs from "@/components/OM/Tabs";

export default {
  components: {
    Tabs,
  },
};
</script>

<style></style>
