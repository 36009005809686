import { render, staticRenderFns } from "./PreviewRelease.vue?vue&type=template&id=4c94ef34"
import script from "./PreviewRelease.vue?vue&type=script&lang=js"
export * from "./PreviewRelease.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports